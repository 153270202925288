var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: !_vm.html,
          expression: "!html"
        }
      ],
      staticClass: "hfillpanel"
    },
    [
      _vm.error
        ? _c(
            "el-alert",
            { attrs: { type: "error" } },
            [
              _vm._v(" " + _vm._s(_vm.error.message) + " "),
              _c("el-button", { on: { click: _vm.loadMessage } }, [
                _vm._v("Retry")
              ])
            ],
            1
          )
        : _vm._e(),
      _c("iframe", {
        ref: "htmlframe",
        staticClass: "fill",
        on: { load: _vm.onHtmlFrameLoaded }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "messagelist" },
    [
      _c(
        "div",
        { staticClass: "toolbar" },
        [
          _c(
            "confirmationdialog",
            {
              attrs: {
                "always-key": "deleteAllMessages",
                message: "Are you sure you want to delete all messages?"
              },
              on: { confirm: _vm.clear }
            },
            [
              _c("el-button", {
                attrs: { icon: "el-icon-close", title: "Clear" }
              })
            ],
            1
          ),
          _c("el-button", {
            attrs: {
              icon: "el-icon-delete",
              disabled: !_vm.selectedmessage,
              title: "Delete"
            },
            on: { click: _vm.deleteSelected }
          }),
          _c("el-button", {
            attrs: {
              icon: "el-icon-refresh",
              disabled: _vm.loading,
              title: "Refresh"
            },
            on: { click: _vm.refresh }
          }),
          _c("el-button", {
            attrs: {
              icon: "el-icon-d-arrow-right",
              disabled: !_vm.selectedmessage || !_vm.isRelayAvailable,
              loading: _vm.isRelayInProgress,
              title: "Relay"
            },
            on: { click: _vm.relaySelected }
          }),
          _c("el-input", {
            staticStyle: { float: "right", width: "35%", "min-width": "150px" },
            attrs: {
              clearable: "",
              placeholder: "Search",
              "prefix-icon": "el-icon-search"
            },
            model: {
              value: _vm.searchTerm,
              callback: function($$v) {
                _vm.searchTerm = $$v
              },
              expression: "searchTerm"
            }
          })
        ],
        1
      ),
      _vm.error
        ? _c(
            "el-alert",
            { attrs: { type: "error", title: "Error", "show-icon": "" } },
            [
              _vm._v(" " + _vm._s(_vm.error.message) + " "),
              _c("el-button", { on: { click: _vm.refresh } }, [_vm._v("Retry")])
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          ref: "table",
          staticClass: "table",
          attrs: {
            data: _vm.filteredMessages,
            "empty-text": _vm.emptyText,
            "highlight-current-row": "",
            "default-sort": { prop: "receivedDate", order: "descending" },
            type: "selection",
            "reserve-selection": "true",
            "row-key": "id",
            "row-class-name": _vm.getRowClass,
            stripe: ""
          },
          on: {
            "current-change": _vm.handleCurrentChange,
            "sort-change": _vm.sort
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              property: "receivedDate",
              label: "Received",
              width: "180",
              sortable: "custom",
              formatter: _vm.formatDate
            }
          }),
          _c("el-table-column", {
            attrs: {
              property: "from",
              label: "From",
              width: "120",
              sortable: "custom"
            }
          }),
          _c("el-table-column", {
            attrs: {
              property: "to",
              label: "To",
              width: "120",
              sortable: "custom"
            }
          }),
          _c("el-table-column", {
            attrs: {
              property: "subject",
              label: "Subject",
              sortable: "custom"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.row.subject) + " "),
                    scope.row.attachmentCount
                      ? _c("i", {
                          staticClass: "el-icon-paperclip",
                          attrs: {
                            title: scope.row.attachmentCount + " attachments"
                          }
                        })
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
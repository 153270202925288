var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ]
    },
    [
      _vm.server && _vm.server.isRunning
        ? _c(
            "el-button",
            {
              attrs: { icon: "el-icon-circle-check", type: "text" },
              on: { click: _vm.stop }
            },
            [
              _vm._v(
                " SMTP server listening on port " +
                  _vm._s(_vm.server.portNumber)
              )
            ]
          )
        : _vm._e(),
      _vm.server && !_vm.server.isRunning && !_vm.server.exception
        ? _c(
            "el-button",
            {
              attrs: { icon: "el-icon-circle-close", type: "danger" },
              on: { click: _vm.start }
            },
            [_vm._v(" SMTP server stopped")]
          )
        : _vm._e(),
      _vm.server && !_vm.server.isRunning && _vm.server.exception
        ? _c(
            "el-button",
            {
              attrs: { icon: "el-icon-circle-close", type: "danger" },
              on: { click: _vm.start }
            },
            [
              _vm._v(" SMTP server error:"),
              _c("br"),
              _vm._v(_vm._s(_vm.server.exception))
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }























































































import Vue from "vue";
import { Form } from 'element-ui'
import { Component, Prop, Watch } from "vue-property-decorator";
import HubConnectionManager from "../HubConnectionManager";
import { Mutex } from "async-mutex";
import ServerController from "../ApiClient/ServerController";
import Server from "../ApiClient/Server";

@Component
export default class SettingsDialog extends Vue {

    constructor() {
        super();
    }

    @Prop({ default: null })
    connection: HubConnectionManager | null = null;

    error: Error | null = null;
    private mutex = new Mutex();
    loading: boolean = true;
    saving: boolean = false;
    server: Server | null = null;

    get rules() {
        let result = {
            server: {
                hostName: [{ required: true, message: 'Hostname is required' }],
                portNumber: [{ required: true, message: 'Port number is required' }],
                numberOfMessagesToKeep: [{ required: true, message: '# of Messages to Keep is required' }],
                numberOfSessionsToKeep: [{ required: true, message: '# of Sessions to Keep is required' }],
                relayOptions: {
                    smtpServer: <object[]>[],
                    smtpPort: <object[]>[]
                }
            }
        };

        if (this.isRelayEnabled) {
            result.server.relayOptions.smtpServer.push({ required: true, message: "SMTP server is required if relaying enabled" });
            result.server.relayOptions.smtpPort.push({ required: true, message: "SMTP port is required if relaying enabled" });
        }

        return result;

    }

    @Prop({ default: false })
    visible: boolean = false;

    handleClose() {
       this.$emit('closed');
    }


    private isRelayEnabledValue: boolean = false;
    get isRelayEnabled() {
        return this.isRelayEnabledValue;
    }

    set isRelayEnabled(value: boolean) {
        this.isRelayEnabledValue = value;
        if (!this.isRelayEnabledValue && this.server) {
            this.server.relayOptions.smtpServer = "";
            this.server.relayOptions.automaticEmails.splice(0, this.server.relayOptions.automaticEmails.length);
        }
    }
         
    relayOptionsAutomaticEmails: any[] = [];

    @Watch("server.relayOptions.automaticEmails")
    updateAutomaticEmailsForValidation() {

        if (!this.server) {
            return;
        }

        this.relayOptionsAutomaticEmails = this.server.relayOptions.automaticEmails.map((v, i) => {
            return { value: v };
        });

    }

    async save() {
        this.saving = true;
        this.error = null;
        try {
            let valid =  await (<Form>this.$refs["form"]).validate()

            if (valid) {

                await new ServerController().updateServer(this.server!);
                this.handleClose();
            }
        } catch (e) {
            this.error = e;
        } finally {
            this.saving = false;
        }
    }

    async refresh(silent: boolean = false) {
        var unlock = await this.mutex.acquire();

        try {
            this.error = null;
            this.loading = !silent;

            this.server = await new ServerController().getServer();
            this.isRelayEnabled = !!this.server.relayOptions.smtpServer;
        } catch (e) {
            this.error = e;
        } finally {
            this.loading = false;
            unlock();
        }
    }

    async mounted() {
        await this.refresh();
    }

    @Watch("connection")
    onConnectionChanged() {
        if (this.connection) {
            this.connection.on("serverchanged", async () => {
                await this.refresh();
            });

            this.connection.addOnConnectedCallback(() => this.refresh());
        }
    }
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-popover",
        {
          attrs: { placement: "bottom", width: "160", trigger: "manual" },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c("p", [_vm._v(_vm._s(_vm.message))]),
          _c(
            "div",
            { staticStyle: { "text-align": "right", margin: "0" } },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "text" },
                  on: {
                    click: function($event) {
                      return _vm.cancel()
                    }
                  }
                },
                [_vm._v("cancel")]
              ),
              _vm.alwaysIsAvailable()
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "text" },
                      on: {
                        click: function($event) {
                          return _vm.confirm(true)
                        }
                      }
                    },
                    [_vm._v("always")]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function($event) {
                      return _vm.confirm(false)
                    }
                  }
                },
                [_vm._v("confirm")]
              )
            ],
            1
          ),
          _vm._t("default", null, { slot: "reference" })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }